<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"/>
              <b-button variant="primary" :to="{ name: 'customerCreate' }">
                <span class="text-nowrap">{{ $t("Add Customer") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
      :per-page="perPage"
        :current-page="currentPage"
        :items="member_list_filter"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(actions)="data">
          <template v-if="isMobile === true">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{name: 'facilityshop', params:{ personID: data.item.person_id }}">
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">{{$t("Ürün Sat")}}</span>
              </b-dropdown-item>
              
              <b-dropdown-item
                :to="{name: 'customerProducts', params: { personID: data.item.person_id }}">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{$t("Products")}}</span>
              </b-dropdown-item>


              <b-dropdown-item
                :to="{name: 'customerEdit', params: { personID: data.item.person_id }}">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.is_confirm"
                :variant="
                  data.item.is_active === '0' || data.item.is_active === false
                    ? 'success'
                    : 'danger'
                "
                @click="member_status_change(data.item.person_id, data.item.is_active)"
              >
                <feather-icon icon="ActivityIcon" />
                <span class="align-middle ml-50">
                  {{
                    data.item.is_active === "0" || data.item.is_active === false
                      ? $t("Activate")
                      : $t("Deactivate")
                  }}
                </span>
              </b-dropdown-item>
              <b-button size="sm" v-if="!data.item.is_confirm" variant="primary"
                @click="reSendInvite(data.item.company_branch_person_role_id, data.item.person_id)">
                {{$t("Re-Send Invite")}}
              </b-button>
            </b-dropdown>
          </template>
          <template v-if="isMobile === false">
            <b-button size="sm" class="mr-1" variant="dark"
              :to="{name: 'facilityshop',params: {personID: data.item.person_id }}">{{ $t("Sell Products") }}</b-button>

             
            <b-button size="sm" class="mr-1" variant="success"
              :to="{name: 'customerProducts',params: {personID: data.item.person_id }}">{{ $t("Products") }}</b-button>  

            <b-button size="sm" class="mr-1" variant="primary"
              :to="{name: 'customerEdit',params: { personID: data.item.person_id }}">{{ $t("Edit") }}</b-button>
            <b-button  size="sm" v-if="data.item.is_confirm" :variant="
                data.item.is_active === '0' || data.item.is_active === false
                  ? 'success'
                  : 'danger'"
              @click="member_status_change(data.item.person_id, data.item.is_active)">{{
                data.item.is_active === "0" || data.item.is_active === false
                  ? $t("Activate")
                  : $t("Deactivate")}}</b-button>
            <b-button size="sm" v-if="!data.item.is_confirm" variant="primary"
            @click="reSendInvite(data.item.company_branch_person_role_id, data.item.person_id)">
              {{ $t("Re-Send Invite") }}
            </b-button>
          </template>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-left
              justify-content-right justify-content-sm-start
              radius
              0
            ">
            <b-form-select
              v-model="selected"
              :options="options"
              @change="changeFiltering"
              class="select-config"
            >
            </b-form-select>
          </b-col>
          <small v-if="$i18n.locale === 'tr'" class="mt-1 strong"
            >içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
              currentPage * perPage
            }}
            arası ({{ currentPage }}.sayfa) gösteriliyor
          </small>
          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage*1}}-{{currentPage*perPage}} (page {{currentPage}}) of {{totalRows}} records</small>
          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,

    vSelect,
  },

  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,

      filterOn: [],

      locale: this.$i18n.locale,

      tableColumns: [
        //{ key: "is_active", label: this.$t("IS ACTIVE"), sortable: true },
        {
          key: 'customer_name',
          label: this.$t('FULL NAME'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('CUSTOMER EMAIL'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('TRANSACTIONS')
        },
      ],
      selected: "all",
      options: [
        { text: this.$t("All Customers"), value: "all" },
        { text: this.$t("Active Customers"), value: "aktif" },
        { text: this.$t("Deactive Customers"), value: "deaktif" },
      
      ],
      companyBranchID: "",
      member_list_filter: [],
      member_list: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.member_list_filter
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    member_list_filter(val) {
     this.totalRows = val.length;
    },
  },
  methods: {
    //müşterilerşn aktiflik, deaktiflik durumlarına göre filtrelenebilmesi
     changeFiltering(arg) {
      if (arg === "all")
      {
        this.getCustomerList();
      } else if (arg === "aktif") {

        //aktif seçilirse base veriler içinde map et aktif true olanları tablo verisi olarak al
        let data = [];
        this.member_list.map((x) => {
          if ((x.is_active === "1" || x.is_active === true) && (x.is_confirm === "1" || x.is_confirm === true)) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.member_list_filter = data;
      } else if (arg === "deaktif") {
        //deaktif seçilirse base veriler içinde map et aktif false olanları tablo verisi olarak al
        let data = [];
        this.member_list.map((x) => {
          if ((x.is_active === "0" || x.is_active === false) && (x.is_confirm === "1" || x.is_confirm === true)) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.member_list_filter = data;
      }
     },
     //Müsterinin aktiflik deaktiflik durumunu değiştir
    member_status_change(val, is_active) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are You Sure You Want To Change The Customer Activity Status?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("Cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (is_active === "0" || is_active === false) {
            this.$database.DistributorService.person_deactive(val, true).then(
              (res) => {
                if (!res.is_success) {
                  this.$functions.Messages.error("Customer Status Didn't Change!");
                } else {
                  this.$functions.Messages.success("Customer status changed");
                  this.getCustomerList();
                }
              }
            );
          }
          if (is_active === "1" || is_active === true) {
            this.$database.DistributorService.person_deactive(val, false).then(
              (res) => {
                if (!res.is_success) {
                  this.$functions.Messages.error("Customer Status Didn't Change!");
                } else {
                  this.$functions.Messages.success("Customer status changed");
                   this.getCustomerList();
                }
              }
            );
          }
        }
      });
    },
    getCustomerList() {
      this.$database.FacilityService.list_company_customers(this.companyBranchID)
        .then(async (res) =>
        {
          if (res.is_success === true || res.is_success === 1) {
            this.member_list = await res.result;
            this.member_list_filter= this.member_list;
          }
        })
    },
    onFiltered(filteredItems)
    {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    reSendInvite(company_branch_person_role_id, person_id)
    {
      this.$database.AccountService.re_send_invite({
        CompanyBranchPersonRoleId: company_branch_person_role_id,
        PersonId: person_id
      }).then(res => {
        // console.log(res);
        if(res.IsSuccess === true || res.IsSuccess === 1){
          this.$functions.Messages.success('Invitation Sent')
        } else {
          this.$functions.Messages.error('Action failed!')
        }
      })
    }
  },
  created() {
    // this.$route.meta.breadcrumb[0].title = 'newTitle'
    this.companyBranchID = this.$store.getters["auth/userInfo"].company_branch_id
    this.getCustomerList();
  },
};
</script>
<style lang="scss" scoped src="@/styles/scss/customerListScoped.scss"></style>
